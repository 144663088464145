// FONTS
@main-font-family: 'Poppins', 'Adjusted Verdana Fallback', 'Verdana';

// COLORS
@font-color: #7B7B7B;
@header-color: #1E1E1E;
@border-color: #d6d6d6;
@main-color: #F58220;
@sec-color: #3D5B77;
@success-color: #38AA30;
@error-color: #E50303;
@light-bg: #F9F9F9;

:root {
    --fullWidth: 500px;
    --fullHeight: 500px;
    --customWidth: 110px;
    --customHeight: 110px;

    --fontSize: 14px;
    --lineHeight: 1.5;
    --rowGap: 50px;

    --prodCols: 4;
    --prodSwiperCols: 5;
    --prodGapHor: 20px;
    --prodGapVert: 20px;
    --prodGap: var(--prodGapVert) var(--prodGapHor);

    --borderRadius: 5px;
    --iconSize: 24px;
    --swiper-navigation-sides-offset: 10px;
    --cartGap: 35px;
    --animSpeed: 0.2s;

    --headerScrollTransform: -85px;
    --headerHeightChange: 10px;
}

// RWD PRODUCT LISTS
@media (max-width: 1460px) and (min-width: 1280px) {
    :root {
        --rowGap: 30px;
        --prodGapHor: 15px;
        --prodGapVert: 15px;
    }
}

@media (max-width: 1279px) {
    :root {
        --rowGap: 25px;
        --fontSize: 13px;
        --lineHeight: 1.4;
        --prodCols: 4;
        --prodSwiperCols: 4;
        --prodGapHor: 10px;
        --prodGapVert: 10px;
        --headerScrollTransform: -29px;
        --headerHeightChange: 0;
    }
}

@media (max-width: 768px) {
    :root {
        --prodCols: 3;
        --prodSwiperCols: 3;
    }
}

@media (max-width: 600px) {
    :root {
        --prodCols: 2;
        --prodSwiperCols: 2;
    }
}

@media (max-width: 359px) {
    :root {
        --prodCols: 1;
        --prodSwiperCols: 1;
    }
}

.slider-with-products .product { // prevents f5 CLS
    width: ~"calc((100% - ( (var(--prodSwiperCols) - 1) * var(--prodGapHor))) / var(--prodSwiperCols ))";
    margin-right: var(--prodGapHor);
}

// RESET
html {
    box-sizing: border-box;
}
body {
   
    font-family: @main-font-family;
    font-size: var(--fontSize);
    color: @font-color;
    line-height: var(--lineHeight);
}

.inner {
    width: 1440px;
    margin: 0 auto;
}

@media (prefers-reduced-motion) {
    *, *:before, *:after {
        transition-duration: unset !important;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
    margin: 0;
    padding: 0;
}

ul, ol {
    list-style: none;
}

a:active,
a:visited,
a:focus,
a {
    text-decoration: none;
    outline: none;
    color: @main-color;
}

a img {
    border: none;
}

textarea, input, button {
    -webkit-appearance: none;
    outline: none;
    border-radius: var(--borderRadius);
    border: 1px solid @border-color;
    color: @font-color;
}

button {
    border: 0;
    background: none;
    text-align: center;
    cursor: pointer;
    font-family: inherit;
    font-size: inherit;
}

button::-moz-focus-inner,
input[type=button]::-moz-focus-inner,
input[type=submit]::-moz-focus-inner,
input[type=reset]::-moz-focus-inner {
    padding: 0 !important;
    border: 0 none !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
}

input {
    &::-webkit-input-placeholder { // don't combine
        opacity: 1;
    }
    &::-moz-placeholder {
        opacity: 1;
    }
    &:-ms-input-placeholder {
        opacity: 1;
    }
    &:-moz-placeholder {
        opacity: 1;
    }
}

input::-ms-clear {
    display: none;
}

h1, h2, h3, h4, h5, h6, h7, h8 {
    display: block;
    clear: both;
    color: @header-color;
    font-weight: 700;
}

hr {
    margin: 30px 0;
    border: none;
    height: 1px;
    background-color: @border-color;
}

// INPUTS
input[type=text],
input[type=password],
input[type=email],
select {
    padding: 0 18px;
    width: 100%;
    border: 1px solid @border-color;
    height: 50px;
    line-height: normal;
    font-size: inherit;
    font-family: inherit;
    background-color: white;
    position: relative;
    border-radius: var(--borderRadius);
}

select {
    padding-block: 10px 0;
    color: @header-color;
    cursor: pointer;
}

textarea {
    padding: 10px 18px;
    font-size: inherit;
    font-family: inherit;
    width: 100%;
    resize: vertical;
    border: 1px solid @border-color;
    border-radius: var(--borderRadius);
}

.form-hint {
    padding: 4px 0;
    color: @sec-color;
    font-size: 12px;
}

input,
select {
    font-family: inherit;
}

select option {
    font-family: Arial, sans-serif;
}

input[type="radio"]:focus-visible + label,
input[type="checkbox"]:focus-visible + label,
*:focus-visible {
    outline: 2px dotted @main-color !important;
    box-shadow: 0 0 1px white;
    outline-offset: 1px;
}

iframe:focus-visible {
    outline: none !important;
}

input[type="checkbox"],
input[type="radio"] {
    height: 100%;
}

img.lazyload,
.swiper-lazy {
    opacity: 0;
    transition: opacity var(--animSpeed) ease-in-out;

    &.loaded,
    &.swiper-lazy-loaded {
        opacity: 1;
    }
}

main {
    .grid();
    padding: 0 0 var(--rowGap);
    grid-gap: var(--rowGap) 44px;

    &:not(.aside) {
        grid-template-columns: minmax(1px, 1fr);

        #tree {
            display: none;
        }
    }

    &.aside {
        grid-template-columns: 280px minmax(1px, 1fr);
        grid-gap: var(--rowGap) 44px;

        #breadcrumb,
        #newsletter,
        .producers-banner {
            grid-column: span 2;
        }
    }
}

// HEADLINE
.headline {
    clear: both;
    font-size: 23px;
    line-height: 1.3;
    display: block;
    font-weight: 600;
    color: @header-color;
    margin-bottom: 19px;

    &h3 {
        font-size: 22px;
    }
}

// BUTTONS
.confirm-button,
.return-button {
    font-weight: bold;
    font-size: 16px;
    padding: 0 30px;
    cursor: pointer;
    border-radius: var(--borderRadius);
    height: 50px;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    width: fit-content;
}

.confirm-button,
a.confirm-button {
    background-color: @sec-color;
    color: white;
}

.return-button,
a.return-button {
    background-color: white;
    color: @font-color;
    border: 1px solid @border-color;

}

@media (min-width: 1280px) {

    .confirm-button:hover,
    a.confirm-button:hover {
        background-color: darken(@sec-color, 10%);
    }

    .return-button:hover,
    a.return-button:hover {
        color: @header-color;
        border-color: @header-color;
    }
}

.confirm-button[disabled],
.confirm-button.button-disabled,
[data-submitting] .confirm-button {

    &:not(.facebook-login-button):not(.google-login-button):not(.apple-login-button) {
        background-color: @border-color;
        color: @font-color !important;
        cursor: not-allowed;
    }
}

// SWIPER SETTINGS
.swiper-button-prev,
.swiper-button-next {
    border-radius: var(--borderRadius);
    background-color: @light-bg;
    width: 30px;
    height: 30px;
    margin-top: -15px;

    &:after {
        content: '\e800';
        font-family: icomoon;
        font-size: 9px;
        color: @header-color;
    }

    @media (min-width: 1280px) {
        &:hover:not(.swiper-button-disabled) {
            background-color: #C5C5C5;

            &:after {
                color: white;
            }
        }
    }

    &.swiper-button-disabled {
        pointer-events: auto;
    }
}

.swiper-button-prev:after {
    transform: rotate(90deg);
}

.swiper-button-next:after {
    transform: rotate(-90deg);
}

.swiper-slide {
    box-sizing: border-box;
}

.swiper-slide:focus-visible {
    outline-offset: -3px;

    img {
        position: static;
    }
}

/////////////////////////////////////

// METHODS
.img-crop { // dont add ()
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}

.img-scale-down { // dont add ()
    width: 100%;
    height: 100%;
    object-fit: scale-down;
    display: block;
}

.setup-appear() {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all var(--animSpeed) ease-in-out;
    transition-property: opacity, visibility, transform, box-shadow;
}

.appear() {
    opacity: 1;
    visibility: visible;
    transform: none;
}

.scrollbar() { // chrome only
    @supports(selector(::-webkit-scrollbar)) {

        &::-webkit-scrollbar-track {
            border-radius: 8px;
            background: darken(@light-bg, 3%);
        }

        &::-webkit-scrollbar {
            height: 5px;
            width: 5px;
            background-color: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 8px;
            -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.3);
            background-color: lighten(@border-color, 5%);

            &:hover {
                background: darken(@border-color, 5%);
            }

            &:active {
                background: darken(@border-color, 15%);
            }
        }
    }
}

.center-lr() {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.line-height() {
    line-height: ~"calc(var(--fontSize) * var(--lineHeight))";
}

.shadow() {
    box-shadow: 0 3px 8px rgba(0,0,0,.2);
}

.grid() {
    display: grid;
    grid-auto-rows: max-content;
}

.small-icon {
    font-family: icomoon;
    line-height: 1;
    display: block;
    font-weight: normal;
    font-style: normal;
    text-transform: none;
    text-decoration: none;
    color: inherit;
    font-size: var(--iconSize);
}

.hr-after {
    content: "";
    background-color: @border-color;
    position: absolute;
    width: 101vw;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

.full-bg {
    content: "";
    background-color: inherit;
    position: absolute;
    width: 101vw;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}

// USER CUSTOM CONTENT
.user-custom-content {

    .gpsr-header {
        margin-top: 135px;
    }

    img:not(.img-scale-down):not(img-crop) {
        height: auto !important;
        max-width: 100% !important;
    }

    p {
        margin-bottom: 14px;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin: 14px 0;
        list-style: disc;
        padding-left: 15px;

        > li::marker {
            color: @main-color;
            font-size: 1.1em;
        }
    }

    ol {
        list-style: decimal !important;
        padding-left: 20px;
        margin: 14px 0;
    }

    ul,
    ol {

        a {
            color: @sec-color;
            font-weight: 600;
        }
    }

    hr {
        margin: 0 0 23px !important;
    }

    h1:not(.headline) {
        font-size: 1.8em;
    }

    h2:not(.headline) {
        font-size: 1.5em;
    }

    h3:not(.headline) {
        font-size: 1.2em;
    }
}

// TABS
.gs-tabs {

    &:empty {
        display: none;
    }

    &.tab-done {

        .products-list-tabs {

            > *:first-child {
                display: none;
            }
        }
    }

    nav {
        display: flex;
        touch-action: manipulation;
    }

    &:not(.tab-done) .products-list-tabs {

        &:nth-of-type(n+2) {
            display: none;
        }
    }
}

// LOADER
@keyframes spin {
    0% {
        transform: translateZ(0) rotate(0deg);
    }

    100% {
        transform: translateZ(0) rotate(1turn);
    }
}

#loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255,255,255,.5);
    z-index: 99999;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity .2s linear;
    pointer-events: none;

    &.show {
        opacity: 1;
        pointer-events: auto;
    }

    div {
        border-radius: 50%;
        border: 4px solid @border-color;
        border-top-color: @header-color;
        animation: spin .5s infinite linear;
        transform: translateZ(0) rotate(0deg);
        transform-origin: center;
        -webkit-transform-style: preserve-3d;
        width: 100px;
        height: 100px;
    }
}

// ICONS
.icon-filter:before {
  content: "\e912";
}
.icon-save:before {
  content: "\e90f";
}
.icon-file:before {
  content: "\e910";
}
.icon-menu:before {
  content: "\e911";
}
.icon-main-cart:before {
  content: "\e90e";
}
.icon-x:before {
  content: "\e90d";
}
.icon-cart-icon4:before {
  content: "\e90c";
}
.icon-cart-icon1:before {
  content: "\e91d";
}
.icon-cart-icon2:before {
  content: "\e91e";
}
.icon-cart-icon3:before {
  content: "\e91f";
}
.icon-cart-icon5:before {
  content: "\e920";
}
.icon-cart-icon6:before {
  content: "\e921";
}
.icon-cart-icon7:before {
  content: "\e922";
}
.icon-cart-icon8:before {
  content: "\e923";
}
.icon-cart-icon9:before {
  content: "\e924";
}
.icon-keyboard_arrow_left:before {
  content: "\e90b";
}
.icon-trash-2:before {
  content: "\e90a";
}
.icon-circle:before {
  content: "\e905";
}
.icon-alert-circle:before {
  content: "\e901";
}
.icon-help-circle:before {
  content: "\e902";
}
.icon-info:before {
  content: "\e900";
}
.icon-check:before {
  content: "\e801";
}
.icon-check-square:before {
  content: "\e816";
}
.icon-credit-card:before {
  content: "\e815";
}
.icon-lock:before {
  content: "\e812";
}
.icon-smartphone:before {
  content: "\e813";
}
.icon-square:before {
  content: "\f096";
}
.icon-star-empty:before {
  content: "\e810";
}
.icon-star:before {
  content: "\e80f";
}
.icon-review:before {
  content: "\e80c";
}
.icon-chat:before {
  content: "\e814";
}
.icon-clock:before {
  content: "\e802";
}
.icon-box:before {
  content: "\e80b";
}
.icon-close:before {
  content: "\e803";
}
.icon-pin:before {
  content: "\e808";
}
.icon-arrow:before {
  content: "\e800";
}
.icon-search:before {
  content: "\e80d";
}
.icon-user:before {
  content: "\e811";
}
.icon-heart:before {
  content: "\e809";
}
.icon-cart:before {
  content: "\e80e";
}
.icon-instagram:before {
  content: "\e807";
}
.icon-facebook1:before {
  content: "\e806";
}
.icon-mail:before {
  content: "\e805";
}
.icon-phone:before {
  content: "\e80a";
}
.icon-truck:before {
  content: "\e804";
}
.icon-alert-circle1:before {
  content: "\e903";
}
.icon-check-circle:before {
  content: "\e904";
}
.icon-youtube-play:before {
  content: "\e909";
}
.icon-pinterest-p:before {
  content: "\e906";
}
.icon-facebook:before {
  content: "\e907";
}
.icon-facebook-f:before {
  content: "\e907";
}
.icon-twitter:before {
  content: "\e908";
}

// IMPORTS
@import (reference) "../settings.less";

// MAIN BANNER
.main-banner-wrapper {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    margin-top: 24px;
}

.main-banner {
    max-width: 950px;
    aspect-ratio: ~"950/402";
    margin: 0;
    background-color: darken(@light-bg, 7%);
    border-radius: var(--borderRadius);

    a {
        display: block;
    }

    .swiper-slide {
        overflow: hidden;
        border-radius: inherit;
    }

    img {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        width: auto;
        height: auto;
        position: absolute;
        transition: opacity var(--animSpeed) ease-in-out;

        &:not([src]) {
            opacity: 0;
        }

        &[src] {
            opacity: 1;
        }
    }

    .text-wrapper {
        position: absolute;
        bottom: 45px;
        left: 24px;
        background-color: rgba(61, 91, 119, 0.90);
        color: white;
        display: flex;
        flex-direction: column;
        padding: 10px 74px 10px 24px;

        b {
            font-size: 24px;
            font-weight: 600;
        }

        span {
            font-size: 16px;
            color: #DBE1E6;
        }
    }
}

.swiper-pagination.banner-pagination {
    position: absolute;
    bottom: 7px;
    left: 24px;
    width: auto;

    .swiper-pagination-bullet {
        border-radius: 0;
        opacity: 1;
        margin: 0;
        width: 22px;
        height: 22px;
        position: relative;
        margin-right: 4px;
        background-color: transparent;

        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            background-color: @sec-color;
            height: 4px;
            transform: translateY(-50%);
        }

        &.swiper-pagination-bullet-active {
            width: 12px;

            &:after {
                background-color: white;
            }
        }
    }
}

// MAIN GREETING
.main-greeting {
    width: 470px;
    height: 402px;
    background-color: @sec-color;
    padding: 32px;
    display: flex;
    flex-direction: column;
    border-radius: var(--borderRadius);
}

.main-greeting-headline {
    color: white;
    font-size: 15px;
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 3px;

    em {
        display: block;
        font-style: normal;
        text-transform: none;
        font-weight: 600;
        font-size: 30px;
        margin-top: -7px;
    }

    span {
        color: @main-color;
    }
}

.main-greeting-text {
    color: white;
    line-height: 1.7;

    p {

        &:nth-child(2) {
            margin-top: 17px;
        }
    }
}

a.main-greeting-link {
    background-color: white;
    color: @sec-color;
    text-align: center;
    margin-top: 17px;
    line-height: 3.4;
    font-weight: 600;
    margin-top: auto;

    &:hover {
        color: white;
        background-color: #C5C5C5;
    }
}

// ICON BANNER
.icon-banner-wrapper {
    .grid();
    grid-template-columns: repeat(4, minmax(0, 1fr));
    gap: 36px;
}

.icon-banner {
    border: 2px solid @border-color;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    min-height: 130px;
    justify-content: center;
    border-radius: var(--borderRadius);

    svg {
        height: 34px;
        margin-bottom: 8px;
    }
}

.icon-banner-title {
    color: @header-color;
}

.icon-banner-subtitle {
    font-size: 13px;
    color: @header-color;
}

// MAIN PAGE SLIDERS
.slider-with-products {
    position: relative;

    .headline {
        margin-bottom: 24px;
        float: left;
    }

    .swiper {
        clear: both;
    }

    .swiper-button-prev,
    .swiper-button-next {
        position: relative;
        top: auto;
        margin-top: 1px;
        float: left;
    }

    .swiper-button-prev {
        left: auto;
        margin-left: 30px;
    }

    .swiper-button-next {
        right: auto;
        margin-left: 4px;
    }
}

a.main-page-product-all {
    float: right;
    color: @font-color;
    margin-top: 6px;
    line-height: 21px;
    display: flex;

    &:after {
        content: '\e800';
        font-family: icomoon;
        font-size: 9px;
        transform: rotate(-90deg);
        margin-left: 8px;
    }

    &:hover {
        color: @header-color;
    }
}